<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="400">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="green white--text" v-bind="attrs" v-on="on" >
                    New List
                </v-btn>
            </template>
            <v-form @submit="createList()" v-model="isValid" v-on:submit.prevent>
                <v-card>
                    <v-card-title class="headline">
                        New List
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="newListName" ref="listNameField" label="Name" :rules="lettersNumbersAndSpaces" required/>

                        <div>
                            <v-select v-model="editPublicAccess" label="Public Access" :items="['None', 'Visible', 'Edit', 'Add Only']" ></v-select>
                        </div>
                        <div>
                            <v-select v-model="editFriendAccess" label="Friend Access" :items="['None', 'Visible', 'Edit', 'Add Only']" ></v-select>
                        </div>

                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn type="submit" :disabled="!isValid" color="green white--text" >
                            SAVE
                        </v-btn>
                    </v-card-actions>
                    <v-card-text v-if="dupeListLink!=''" class="mt-10">
                        <h3> A list by this name already exists. You can view it here </h3>
                        <h3> <a :href="dupeListLink">{{dupeListLink}}</a> </h3>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-dialog>
    </v-row>
</template>
<script>
    import api from '/src/utils/api';

    const NewListModal = {
        data() {
            return {
                dialog: false,
                newListName: "",
                dupeListLink: "",
                editFriendAccess: "None",
                editPublicAccess: "None",
                isValid:false,
                lettersNumbersAndSpaces: [
                    str => !!str || 'Name is required',
                    str => /^[A-Za-z\d\s]*$/.test(str) || 'Only letters, numbers and spaces are allowed.',
                ],
            }
        },
        methods: {
            createList() {
                // Check if new list name already exists. If it does, provide a link to the user to view this list.
                if (this.existingLists.filter(l => l.listName === this.newListName).length > 0) {
                    this.dupeListLink = window.location.origin + "/" + this.channelName + "/" + this.newListName;
                }
                else {
                    let body = {channelId:this.channelId, listName:this.newListName, publicAccess: this.editPublicAccess, friendAccess: this.editFriendAccess};
                    api.postAuthRequired('create-list', body).then((res) => {
                        this.newListName = "";
                        this.dialog = false;
                        this.handleSuccess();
                    });
                }
            },
            async focusInputField() {
                // Delay to give time for the dialog to render.
                await new Promise((resolve) => setTimeout(resolve, 100));
                this.$refs.listNameField.focus();
            },
        },
        watch: {
            dialog(isDisplayed) {
                if (isDisplayed) {
                    this.focusInputField();
                }
            },
        },
        props: ['channelId','handleSuccess','existingLists','channelName'],
    }
    export default NewListModal;
</script>

